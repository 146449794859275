import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export type domainState = string;

const initialState = '';
const domain = createSlice({
    name: 'Domain',
    initialState,
    reducers: {
        setDomain: (_state, action: PayloadAction<domainState>) =>
            action.payload,
    },
});

export const { setDomain } = domain.actions;
export default domain.reducer;

export const domainSelector = (state: RootState) => state.application.domain;
