import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/ducks';
import { LiteShopContext } from 'index';

export type ContextState = LiteShopContext;

const initialState: ContextState = {
    name: 'unknown',
};

const context = createSlice({
    name: 'Context',
    initialState,
    reducers: {
        setContextData: (_state, action: PayloadAction<ContextState>) =>
            action.payload,
    },
});
export const contextActions = context.actions;
export default context.reducer;

export const contextSelector = (state: RootState) => state.application.context;
