import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCatalogTagsResponse } from '@api/types/catalog';
import { RootState } from '..';

export type TagsState = ApiCatalogTagsResponse;

const initialState: TagsState = [];
const tags = createSlice({
    name: 'Tags',
    initialState,
    reducers: {
        setTags: (_state: TagsState, action: PayloadAction<TagsState>) =>
            action.payload,
    },
});

export const tagsActions = tags.actions;
export default tags.reducer;

export const tagsSelector = (state: RootState) => state.application.tags;
