import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '..';
import { LiteShopContext } from 'index';
import { Context, CONTEXT, ContextName } from '@vsemayki/url-resolver';
import keyBy from 'lodash/keyBy';
import { tagsSelector } from './tags';
import { structureSelector } from './structure';
import { contextSelector } from './context';

export type BreadcrumbsItem = { title: string; context: LiteShopContext };
export type BreadcrumbsState = BreadcrumbsItem[];

const initialState: BreadcrumbsState = [];

const breadcrumbs = createSlice({
    name: 'Breadcrumbs',
    initialState,
    reducers: {
        setBreadcrumbs: (_state, action: PayloadAction<BreadcrumbsState>) =>
            action.payload,
    },
});

export const breadcrumbsActions = breadcrumbs.actions;
export default breadcrumbs.reducer;

export const breadcrumbsSelector = (state: RootState) =>
    state.application.breadcrumbs;

export const buildBreadcrumbs: ({
    tag,
    product,
}: {
    tag?: string;
    product?: string;
}) => AppThunk<Promise<void>> = ({ tag = '', product }) => async (
    dispatch,
    getState
) => {
    const StoreState = getState();
    const ContextState = contextSelector(StoreState);
    const context = new Context();
    const ContextNameMapper: Record<ContextName, string> = {
        [CONTEXT.index]: 'Главная',
        [CONTEXT.catalog]: 'Каталог',
        [CONTEXT.catalogue]: 'Каталог',
        [CONTEXT.cart]: 'Корзина',
        [CONTEXT.constructor]: 'Конструктор',
        [CONTEXT.cartDelivery]: 'Доставка',
        [CONTEXT.favorites]: 'Избранное',
        [CONTEXT.orderSuccess]: 'Успешный заказ',
        [CONTEXT.prepare]: 'Подготовка',
        [CONTEXT.product]: 'Товар',
        [CONTEXT.article]: 'Товар',
        [CONTEXT.static]: 'Статичная',
        [CONTEXT.status]: 'Статус заказа',
        [CONTEXT.unknown]: '',
        [CONTEXT.list]: 'Список',
    };
    switch (ContextState.name) {
        case CONTEXT.article:
            context.setName(CONTEXT.catalogue);
            break;
        default:
            context.setName(ContextState.name);
            break;
    }
    // slice because arrays are frozen in strict mode;
    const structureSort = structureSelector(StoreState)
        .slice()
        .sort(
            (a, b) =>
                parseInt(a.position as string) - parseInt(b.position as string)
        );

    if (product) {
        const filterType = structureSort.find((item) => item.id === product);
        if (filterType) context.setFilter(filterType.type, product);
    }

    const [cat, subCat] = tag ? tag.split('__') : [];
    context.setCategory(cat);
    context.setSubCategory(subCat);
    // slice because arrays are frozen in strict mode;
    const tagsSort = tagsSelector(StoreState)
        .slice()
        .sort((a, b) => a.position - b.position);
    const tags = keyBy(tagsSort, 'id');

    const breadcrumbs: BreadcrumbsState = [];
    try {
        if (context.getSubCategory()) {
            if (!tags[context.getCategoryAlias()]) {
                context.removeSubCategory();
            } else {
                breadcrumbs.push({
                    title: tags[context.getCategoryAlias()].title,
                    context: context.getContextData() as LiteShopContext,
                });
                context.removeSubCategory();
            }
        }

        if (context.getCategory()) {
            if (!tags[context.getCategoryAlias()]) {
                context.removeCategory();
            } else {
                breadcrumbs.push({
                    title: tags[context.getCategoryAlias()].title,
                    context: context.getContextData() as LiteShopContext,
                });
                context.removeCategory();
            }
        }

        while (context.getFilterValue()) {
            const value = context.getFilterValue();
            const [struct] = structureSort.filter((item) => item.id === value);
            if (!struct) {
                break;
            }

            context.setFilter(struct.type, value);
            breadcrumbs.push({
                title: struct.title,
                context: context.getContextData(),
            });

            if (!struct.parent) {
                context.removeFilter();
            } else {
                context.setFilter(context.getFilterType(), struct.parent);
            }
        }

        breadcrumbs.push({
            title: ContextNameMapper[context.getName()],
            context: { name: context.getName() },
        });

        context.setName(CONTEXT.index);
        // add index breadcrumb
        breadcrumbs.push({
            title: ContextNameMapper[context.getName()],
            context: { name: context.getName() },
        });

        dispatch(breadcrumbsActions.setBreadcrumbs(breadcrumbs.reverse()));
    } catch (error) {
        console.error(error);
        console.error('context :>> ', context);
        console.error('context :>> ', breadcrumbs);
    }
};
