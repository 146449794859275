import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCatalogStructureResponse } from '@api/types/catalog';
import { RootState } from '..';

import keyBy from 'lodash/keyBy';
import { ObjectWithKeyAsValue } from '@src/utils/utils';

export type FilterType = 'v' | 'g' | 't';

export type StructureItem = ApiCatalogStructureResponse[0] & {
    type: FilterType;
};
export type StructureState = StructureItem[];

const initialState: StructureState = [];
const structure = createSlice({
    name: 'Structure',
    initialState,
    reducers: {
        setStructure: (
            _state: StructureState,
            action: PayloadAction<StructureState>
        ) => action.payload,
    },
});

export const structureActions = structure.actions;
export default structure.reducer;

export const structureSelector = (state: RootState) =>
    state.application.structure;

export const setTypesStructureElements = (
    structure: ApiCatalogStructureResponse
) => {
    const types: ObjectWithKeyAsValue<FilterType> = {
        v: 'v',
        g: 'g',
        t: 't',
    };

    const newStructure: StructureItem[] = [];

    const viewTypes = structure
        .filter((item) => !item.parent)
        .map<StructureItem>((item) => {
            return { ...item, type: types.v };
        });

    newStructure.push(...viewTypes);

    const groupTypeDict = keyBy(viewTypes, 'id');
    const groupTypes = structure
        .filter((item) => item.parent && groupTypeDict[item.parent])
        .map<StructureItem>((item) => {
            return { ...item, type: types.g };
        });

    newStructure.push(...groupTypes);

    const subcategoryTypeDict = keyBy(groupTypes, 'id');
    const subcategoryTypes = structure
        .filter((item) => item.parent && subcategoryTypeDict[item.parent])
        .map<StructureItem>((item) => {
            return { ...item, type: types.t };
        });

    newStructure.push(...subcategoryTypes);

    return newStructure;
};
