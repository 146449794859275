import React from 'react';
import * as styles from './styles.scss';
import clsx from 'clsx';

type TitleProps = JSX.IntrinsicElements['h1'];
const Title: React.FC<TitleProps> = (props) => {
    const { children, className, ...h1Props } = props;
    return (
        <h1 {...h1Props} className={clsx(styles.title, className)}>
            {children}
        </h1>
    );
};

export default Title;
